import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import axios from "axios";

export const GetURL = (id) => {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        allMenus(sort:"Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Show_In_Top_Nav
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL            
          }
        }
      }
    }
  `)
    
  let PageURL = data.glstrapi.allMenus.filter(item => item.Primary_URL === id).pop();

  let URL = PageURL.Secondary_URL ? PageURL.Secondary_URL : PageURL.Primary_URL


  if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent == null) ) {
      URL = PageURL.Main_Parent.Primary_URL + '/' + URL
  }

  else if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent != null)) {
      URL = PageURL.Main_Parent.Primary_URL + '/' + PageURL.Sub_Parent.Primary_URL + '/' + URL
  }
  if(PageURL.Secondary_URL) {
    URL =  PageURL.Secondary_URL.split('/').filter(v => v !== '').join('/');    
  }
  return URL;

}


export const NewsDate = (date) => {

	const d = new Date(date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<span>{`${da} ${mo} ${ye}`}</span>
	)
}

export const Offlinedate = (date) => {

	const d = new Date(date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<span>{`${mo} ${ye}`}</span>
	)
}


export const scrollToElement = (element, offset = 1) => {
  const scrollOffset = offset
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  typeof window !== "undefined" &&
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
}

