import React from "react";
import {Link}  from "gatsby"

import { GetURL } from "./functions";


const GenerateLink =(props,{children})=> {
	let url = ''
	if (props.link) {
		url = GetURL(props.link)
	}

	let anchor = undefined

	if(props.link === "mortgage-services-in-dubai" && props.mayAnchor){
		anchor = "#mortgage-calculator"
	}

	
    return(
		<React.Fragment>
			{props.secondarylink?
			url!= "undefined" && url.indexOf("http://") == 0 || url.indexOf("https://") == 0 ?
    		<a href={`${props.secondarylink}`} target="_blank" className={props.class}>{props.children}</a>
			: <Link to={`${props.secondarylink}`} className={props.class}>{props.children}</Link> 
			:
			url!= "undefined" && url.indexOf("http://") == 0 || url.indexOf("https://") == 0 ?
    		<a href={`${url}`} target="_blank" className={props.class}>{props.children}</a>
			: <Link to={`/${url}/${anchor ? anchor : ""}`} className={props.class}>{props.children}</Link> }
			
		</React.Fragment>
    )
}

export default GenerateLink;
